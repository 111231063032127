import React from "react"
import Layout from "../components/Layout/layout"
import PrivacyPolicy from "../components/Privacypolicy/Privacypolicy"

const PrivacyPolicyPage = (
  {pageContext}
) => {

  const {
    footer,
    navBar,
    privacyAndPolicy
  } = pageContext
  return (
    <Layout
      footer={footer}
      navBar={navBar}>
      <PrivacyPolicy data={privacyAndPolicy} />
    </Layout>
  )
}
export default PrivacyPolicyPage
